// Path

$font-path: '../fonts';
$image-path: '../images';

// Vars;
$white: #fff;
$subWhite: #f3f3f3;
$black: #000;
$dark: #303030;
$midDark: #232323;
$subDark: #2e2e2e;
$gray: #ccc;
$darkGray: #666;
$lightGray: #d6d9d8;
$midGray: #dfdfdf;
$subGray: #ddd;
$icoGray: #838c91;

$grayDr: #2d3436;
$grayDm: #636e72;
$grayMd: #b2bec3;
$grayLt: #dfe6e9;
$grayNick: #f3f3f3;
$grayBg: #eeeeee;
$orange: rgb(241,133,51);

$blue: #4b8cf5;

$red: #d31b3a;

$orange: #f58220;


// Fonts

$arial: Arial,Helvetica,sans-serif;

$shellHeight: 114px;
$podHeaderHeight: 60px;
