.agreement {
  width: 600px;
  height: 200px;
  padding: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid gray;

  ul {
    margin: 20px;
    
    li {
      margin-bottom: 12px;
    }
  }

  &-warning {
    width: 600px;
  }
}