.card {
  cursor: pointer;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  display: block;
  position: relative;
  perspective: 1000px;
  animation-name: animateIn;
  animation-duration: .35s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;

  &:before {
    content: "";
    position: absolute;
    top: -14px;
    left: -14px;
    z-index: -1;
  }

  &,
  &:before {
    width: 245px;
    height: 245px;
  }

  &__link {
    background-color: $orange;
    border: 2px solid $white;
    display: block;
    width: 100%;
    height: 100%;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 21px;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      clip-path: polygon(75% 0,100% 50%,75% 100%,0 100%,25% 50%,0 0);
      -webkit-clip-path: polygon(75% 0,100% 50%,75% 100%,0 100%,25% 50%,0 0);
      background-color: $white;
      opacity: 0;
      transition: opacity .5s ease-in,transform .3s ease-in-out 0ms;
    }
  }

  &__content {
    background-color: $white;
    color: $black;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 14px;
    display: flex;
    flex-direction: column;
    border: 2px solid $white;

    &--lhs {
      clip-path: polygon(0 0,51% 0,51% 100%,0 100%);
      -webkit-clip-path: polygon(0 0,51% 0,51% 100%,0 100%);
    }
    
    &--rhs {
      clip-path: polygon(50% 0,100% 0,100% 100%,50% 100%);
      -webkit-clip-path: polygon(50% 0,100% 0,100% 100%,50% 100%);
      transition: transform .5s ease-in-out,background-color .4s ease-in-out;
    }
  }

  &__title {
    font-size: 28px;
    line-height: 1.125;
    font-weight: 700;
    letter-spacing: -.02em;
  }
  
  &__footer {
    margin-top: auto;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    justify-content: space-between;
  }
  
  &__link:hover &__content--rhs {
    background-color: #f5f5f5;
    transform: rotateY(-50deg);
  }
  
  &__link:hover:after {
    opacity: 1;
    transform: translateX(10.5px);
    transition: opacity .5s ease-in,transform .3s ease-in-out .25s;
  }
}
