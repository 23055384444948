// Workout
.table--workout {
  display: grid;
  align-items: start;
  column-gap: 15px;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  padding: 15px;
  .ant-card-head-title {
    font-weight: bold;
    cursor: pointer;
  }
  .ant-card-head-title {
    font-size: 2em;
  }
  .ant-card-body {
    font-size: 1.5em;
  }
  .ant-card__link {
    min-width: 320px;
  }
}

.workout {
  // width: 90%;
  height: calc(100% - #{$podHeaderHeight}); // 56px - height of workout heeader
  width: 991px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
  padding-right: 24px;

  .workout--tab {
    text-align: left;
  }

  .ant-tabs-content-holder {
    overflow: auto;
  }

  .ant-tabs .ant-tabs-tabpane-active {
    // height: 780px;
    overflow: auto;
  }

  .ant-tabs .ant-tabs-left-content {
    overflow: auto;
    height: 100%;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    .map {
      max-height: calc(100vh - #{$shellHeight});
    }
  }

  &__section {
    font-weight: bold;
    color: $icoGray;
    transition: color ease-in .2s;
    
    &-content {
      padding: 8px 24px;
    }

    &--active  {
      color: $black;
    }
  }

  .ant-tabs-tab:not(.ant-tabs-tab-disabled):hover {
    .workout__section {
      color: #ffa44a;

      &[disabled] {
        color: $icoGray;
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-disabled:hover {
    cursor: default;
  }

  .ant-tabs-tab-active .workout__section {
    color: $black !important;
  }

  .ant-tabs-tab {
    margin: 0 !important;
    padding: 0 !important;
    color: $icoGray;
    transition: color ease-in .2s, background-color ease-in .2s;
  }

  .ant-tabs-tab-active {
    background-color: $white !important;
    color: $black;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $black;
  }

  .ant-tabs.ant-tabs-left {
    height: 100%;
  }
  
  .ant-tabs-nav,
  .ant-tabs-bar {
    background-color: #d5d5d5 !important;
  }

  &__tab {
    height: 100%;
  }

  &-content {
    background-color: $subGray;
    padding: 10px;

    &--section {
      padding: 5px;
      margin-bottom: 20px;

      &-white {
        background-color: $white;
        margin-left: 10px;
        padding: 10px 20px;
        margin-right: 10px;
      }

      &-spacing {
        margin-bottom: 40px;
        margin-top: 60px;

        .workout-content--title {
          font-size: 26px;
        }

        .workout-content--title-time {
          font-size: 20px;
        }
      }
    }

    &--time {
      text-transform: initial;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      margin-left: 10px;
    }

    &--title {
      text-transform: uppercase;
      font-size: 20px;

      &-sub {
        font-size: 16px;
      }

      &-time {
        font-size: 14px;
        font-style: italic;
        text-transform: initial;
      }
    }

    .list__item {
      margin-bottom: 10px;
    }
  }

  &-plank {
    text-align: center;
    padding: 10px;
    text-transform: uppercase;
    background-color: black;
    margin-bottom: 4px;
    color: aliceblue;
    font-size: 20px;
    font-weight: 900;

    & + &{
      margin-left: 7px;
    }
  }

  &-menu {
    cursor: pointer;
    background-color: $subGray;
    padding: 3px;

    &-container {
      background-color: $subGray;
      padding: 8px;
      margin-right: 7px;
    }

    &--active {
      background-color: $white;
      color: $orange;

      .workout-menu-title {
        color: $orange;
      }
    }

    &-title {
      padding-left: 5px;
      margin-bottom: 3px;
      text-transform: uppercase;
      font-size: 16px;
    }

    &-option {
      display: flex;
      align-items: center;
      padding: 5px;
      font-size: 11px;
      text-transform: uppercase;

      &:before {
        content: '>';
        margin-right: 10px;
      }
    }
  }

  &-btn {
    cursor: pointer;
    padding: 4px 14px;
    margin-right: 10px;

    &--active {
      color: $orange;
      border-color: $orange;
    }

    &:last-child {
      margin-right: 15px;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  &-menu + &-menu {
    margin-top: 0px;
  }
}
