.map {
  .notes {
    position: absolute;
    z-index: 4;
    bottom: 20px;
    left: 10px;

    display: none;

    max-height: 382px;

    border-radius: 3px;
    background-color: $subWhite;
    box-shadow: 0 2px 2px 0 rgba($grayMd, .8);

    .col {
      max-height: 300px;

      background-color: $subWhite;

      &__content {
        max-height: 300px;
      }

      &__item,
      .textarea {
        background-color: $grayLt;
      }

      &__button {
        background-color: $grayLt;

        &:hover {
          background-color: $grayMd;
        }
      }
    }
  }
}
.workout-notes {
  margin-top: 15px;
  padding: 5px;
  &__title {
    margin-top: 5px;
    font-weight: bold;
    font-size: 18px;
  }
  .col__item {
    border: 2px solid $grayNick;
    margin-bottom: 5px;
  }
}
