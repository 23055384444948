.header {
  height: 52px;
  padding: 12px 20px;

  background-color: $black;

  @include space-between-center-v;

  &-language-select {
    .ant-select-single .ant-select-selector {
      height: 21px;
    }

    .ant-select-single .ant-select-selection-item {
      line-height: 21px;
    }
  }

  &__language-selector {
    height: 21px !important;

    &.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
      height: 21px !important;
      line-height: 21px !important;
    }

    &.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
      height: 21px !important;
      line-height: 21px !important;
    }

    &.ant-select-arrow {
      top: 59%;
    }
  }

  &--admin.ant-layout-header {

    padding: 12px 20px;
    height: 52px !important;
    line-height: 52px;
    .text {
      color: #fff;
    }

    .left > .logo {
      margin-top: 0;
    }

    .ant-menu.ant-menu-horizontal  {
      background-color: transparent;
      line-height: 52px !important;
      margin-left: 40px;

      & > .ant-menu-item {
        border-bottom-width: 3px;
        color: #fff
      }
      .anticon {
        transition: color 0.3s;
      }

      & > .ant-menu-item a {
        color: #fff;
      }

      & > .ant-menu-item:hover, .ant-menu-item-selected {
        a, .anticon {
          color: $orange;
        }
      }

      .ant-badge {
        margin-left: 10px;
      }

    }
    .menu--profile {
      & > .ant-avatar {
        cursor: pointer;
      }
    }
  }

  .left {
    display: flex;

    align-items: center;

    .logo {
      width: 34px;
      margin-top: 4px;
      margin-right: 20px;
    }

    .logo__image {
      width: 100%;
      height: auto;
    }

    .text {
      margin-right: 6px;

      color: $white;

      &--weight-bold {
        &:after {
          content: '::';
          padding-left: 6px;
        }
      }
    }
  }

  .print-btn {
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .right {
    display: flex;
    align-items: center;

    .header__nav {
      display: flex;

      list-style-type: none;
    }

    .header-mobile {
      display: none;
    }

    .header-mobile__nav {
      display: none;
    }

    .header__list-item {
      margin-right: 12px;

      &--no-margin {
        margin-right: 0;
      }

      &.header__submenu {
        margin-right: 20px;
      }
    }

    .button {
      font-weight: bold;

      border-bottom: 1px solid $white;

      &:hover {
        border-color: transparent;
      }
    }
  }

  &__submenu {
    position: relative;

    &:hover {
      .submenu {
        max-height: 100vh;
        box-shadow: 0 2px 2px 0 rgba($black, .6);
        padding: 6px;
      }
    }

    &:after {
      position: absolute;
      top: 8px;
      right: -10px;

      width: 0;
      height: 0;

      content: '';

      border: 4px solid transparent;
      border-color: $white transparent transparent transparent;
    }
  }

  .submenu {
    position: absolute;
    top: 100%;
    left: -6px;
    z-index: 1;
    width: 200px;
    max-height: 0;
    list-style-type: none;
    background-color: $black;
    overflow: hidden;
    box-shadow: none;
    padding: 0 6px;
    &__li {
      .button--role-nav {
        text-align: left;
        font-weight: normal;
        font-size: 14px;
        padding: 4px 0;
        border: none;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
