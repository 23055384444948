@mixin center-h-v {
  display: flex;

  justify-content: center;
  align-items: center;
}

@mixin center-v {
  display: flex;

  align-items: center;
}

@mixin center-h {
  display: flex;

  justify-content: center;
}

@mixin column {
  display: flex;
  flex-direction: column;
}

@mixin space-between-center-v {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

@mixin space-between-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin space-between {
  display: flex;

  justify-content: space-between;
}

@mixin space-end {
  display: flex;
  justify-content: flex-end;
}
