  // BIG ASSUMPTIONS
  .experiments {
    list-style: none;

    counter-reset: counter;

    .experiment {
      display: flex;

      counter-increment: counter;

      width: calc(100% - 12px);

      &::before {
        font-size: 14px;
        margin: 9px 0 0 6px;
        content: counter(counter) '. ';
      }

      .col__item {
        margin-left: 0;
        width: 100%;
        min-height: 48px;
      }
    }
  }