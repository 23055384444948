.flex__wrapper {
  display: flex;

  &--space-between {
    @include space-between;
  }

  &--space-end {
    @include space-end;
  }

  &--center-h-v  {
    @include center-h-v ;
  }

  &--center {
    @include center-h;
  }
}

.top-space {
  margin-top: 45px;
}

.top-space--sm {
  margin-top: 20px;
}

.bottom-space--sm {
  margin-bottom: 20px;
}

.space-around {
  margin: 10px 0;
}

.gap-left {
  margin-left: 20px;

  &--sm {
    margin-left: 5px
  }
}

.has__tooltip {
  display: inline;
  cursor: pointer;
  border-bottom: 1px dotted #b2bec3;
}

mark.highlighted-search-text {
  background-color: $orange;
  font-weight: 700;
  color: #fff;
}
