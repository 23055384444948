.text {

  &--orange {
    color: $orange !important;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--wrap {
    white-space: pre-line;
  }

  &--grey {
    color: grey;
  }

  &--black {
    color: $black;
  }

  &--underlined {
    text-decoration: underline;
    margin-right: 5px;
  }

  &--size {

    &-sm {
      font-size: 12px;
    }

    &-m {
      font-size: 14px;
    }

    &-md {
      font-size: 16px;
    }

    &-l {
      font-size: 18px;
    }

    &-xl {
      font-size: 24px;
    }
  }

  &--weight {

    &-bold {
      font-weight: bold;
    }

    &-normal {
      font-weight: normal;
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &--align {

    &-right {
      text-align: right;
    }

    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }
  }
}


.h {
  font-family: Arial;

  &1 {
    font-size: 48px;

    margin: 20px 0;
  }

  &2 {
    font-size: 40px;

    margin: 16px 0;
  }

  &3 {
    font-size: 32px;

    margin: 12px 0;
  }

  &4 {
    font-size: 17px;

    margin: 8px 0;
  }

  &5 {
    font-size: 16px;

    margin: 6px 0;
  }

  &6 {
    font-size: 14px;

    margin: 4px 0;
  }
}

.link {
  text-decoration: none;

  color: $black;
}

.list {
  list-style-position: inside;
  font-size: 16px;

  .list,
  .ordered-list {
    padding: 10px 0 10px 24px;
  }

  &--outside {
    list-style-position: outside;
    margin-left: 20px;
  }

  &--huge {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
  }
}

.paragraph {
  margin: 10px 0 10px;
}

.paragraph--gap-bottom {
  margin: 10px 0 0 0;
}
