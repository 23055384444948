.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include center-h-v;
  background: rgba($black, .2);

  .modal {
    height: auto;
    padding: 56px 12px 12px;
    box-shadow: 2px 2px 2px 0 rgba($black, .2);
    position: relative;

    .tabs {
      position: absolute;
      left: 0;
      top: 0;
      @include space-between-center-v;
      margin-bottom: 12px;
      width: 100%;
    }

    .tab {
      background: none;
      width: 25%;
      padding: 12px 2px;
      cursor: pointer;
      border: none;
      border: 1px solid $grayMd;
      border-left: none;
      border-top: none;

      &:last-child {
        border-right:none;
      }

      &:hover {
        background: rgba($grayLt, .6);
      }

      &.active {
        font-weight: bold;
        border-bottom-color: transparent;
      }
    }

    .close-icon {
      font-size: 20px;
      cursor: pointer;
      position: absolute;
      top: 8px;
      right: 8px;
    }

    .col__title {
      @include space-between-center-v;

      .h4 {
        margin: 0;
        font-size: 20px;
      }
    }

    &__description {
      margin-bottom: 6px;
    }

    .button {
      width: 120px;
    }

    .label {
      margin: 6px 0;
      user-select: none;

      &-red {
        color: $red;
      }
    }

    .link {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    .success-message {
      margin-bottom: 4px;
    }

    .buttons-wrapper {
      @include space-between;
      margin-top: 8px;
    }

    .checkbox {
      margin: 0 4px 0 0;
    }

    .col__item {
      width: 100%;
      margin-bottom: 4px;
    }

    .col__cell {

      &:last-child {
        padding-bottom: 0;
      }

      .h4 {
        font-size: 14px;
      }

      .col__radios {
        @include space-between-center-v;
        margin: 6px 0;

        &:before,
        &:after {
          width: 62px;
          text-align: center;
          font-size: 14px;
        }

        &:before {
          content: 'Not satisfied';
        }
        &:after {
          content: 'Very satisfied';
        }

        &--chinese {
          &:before {
            content: '不满意';
          }
          &:after {
            content: '非常满意';
          }
        }
      }
    }
  }

  .protocol-modal {
    padding: 30px 12px 12px;

    &-heading.col__title {
      justify-content: center;
      
      .h4 {
        font-size: 30px;
        font-weight: 500;
        border-bottom: 2px solid $orange;
        color: #848484;
        margin-bottom: 10px;
      }
    }

    &-numeration {
      font-size: 20px;
      color: $orange;
    }

    &-copyright {
      text-align: center;
      font-size: 12px;
      margin: 12px 0;
    }

    &-step-content {
      padding: 5px 10px;
      font-size: 15px;
      font-style: italic;

      &-text {
        line-height: 20px;
        padding-left: 35px;
      }

      &-heading {
        color: $orange;
        font-size: 18px;
        font-style: normal;
        padding-left: 15px;
        margin: 5px 0;
      }
    }

    &-step.col__title {
      justify-content: initial;
      font-weight: bold;

      .h4 {
        font-size: 20px;
      }
    }
  }
}
