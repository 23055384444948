.col {
  padding: 12px 0 6px 6px;

  border-right: 2px solid $grayLt;
  background-color: $white;

  position: relative;
  height: 100%;
  @include column;

  &__wrapper {
    padding: 0;

    .col {
      border: none;
      padding: 12px 6px 6px;
      height: auto;

      .col__item {
        width: 100%;
      }

      .col__content {
        padding: 0;
      }

      &:first-child {
        border: 5px solid $grayLt;
        width: 308px;
        margin: 12px 6px 6px;
        height: auto;

        .col__title {
          .h4 {
            font-size: 16px;
            color: $grayDr;
          }
        }

        .col__content {
          min-height: 100px;
        }
      }
    }
  }

  &--scroll-x {
    overflow: auto;
  }

  &--size {
    &-sm {
      width: 320px;
    }

    &-md {
      width: 480px;
    }

    &-lg {
      width: 640px;
    }
    &-xl {
      width: 720px;
    }
  }

  &__cell {
    padding: 0 0 8px;
    width: 100%;
  }

  &:last-child {
    border-right: none;
  }

  .tooltip__target {
    border-bottom: 1px dotted $grayMd;
    cursor: context-menu;

    &--top .tooltip__box {
      top: -34px;
    }

    &:hover {
      border-color: transparent;
    }
  }

  &__title {
    margin-bottom: 6px;

    &--no-margin {
      margin-bottom: 0;
    }

    .h4,
    .h6 {
      margin: 0 6px;

      @include space-between-center-v;
    }

    &--start {
      .h4 {
        justify-content: start;
        align-items: flex-start;
      }
    }
  }

  &__subtitle {
    padding: 2px 14px;

    &--bottom-gap {
      padding: 6px 0;
      margin: 0 6px 10px;
    }
   }

  &__content {
    overflow-y: auto;
    padding-right: 8px;
    height: 100%;

    &::-webkit-scrollbar {
      width: 6px;
      height: auto;

      background-color: $black;
    }

    &::-webkit-scrollbar-track {
      width: 6px;
      height: auto;

      background: $subWhite;
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;

      background: $grayMd;
    }
  }

  &__item {
    position: relative;

    margin: 0 0 2px;
    padding: 6px;
    border: 2px solid transparent;

    width: calc(100% - 12px);

    &.active-input {
      border-color: $grayLt;
    }
  }

  // HIDDEN COMMITMENTS

  &__wb {
    position: relative;

    margin-bottom: 10px;

    border: 5px solid $grayLt;

    .col__subtitle {
      color: $grayDr;

      &.h5 {
        cursor: pointer;
        margin: 6px 0 4px;
      }
    }

    .col__item {
      margin: 0 6px;
      padding: 6px;
      width: calc(100% - 22px);

      &.active-input {
        margin: 6px;
      }
    }

    .wb__content {
      overflow-y: auto;

      min-height: 100px;
    }
  }

  &__sub {
    margin-top: 10px;
  }

  &-experiment {
    .col__title {
      .h4 {
        justify-content: flex-start;
        align-items: flex-start;

        .ba__index {
          margin-right: 5px;
        }
      }
    }
  }
}

.experiment-wrapper {
  height: 100%;
  border-right: 2px solid $grayLt;

  &:last-child {
    border: none;
  }

  .col {
    border: none;
  }
 }

.experiment-title {
  min-height: 64px;
}

.сol-experiment {
  height: 50%;
  margin-bottom: 2px;
}
