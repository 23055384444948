.footer {
  font-size: 14px;
  padding: 12px;
  text-align: center;
  color: $grayMd;

  .privacy-links-wrapper {
    margin-bottom: 10px;
  }

  .link {
    color: $grayMd;
    margin-left: 5px;

    &--bordered:after {
      content: '|';
      margin-left: 5px;
    }

    &--bordered:last-child {
      &:after {
        content: '';
        margin-left: 0;
      }
    }
  }

  .copyright {
    margin-right: 30px;
  }

  &--sprint {
    position: absolute;
    left: 8px;
    bottom: -40px;
  }
}
