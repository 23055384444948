@media (min-width: 1024px) {
  @import 'responsive/_desktop';
}

@media (max-width: 1024px) {
  .header {
    .superscript {display: none;}
  }
}

@media (max-width: 1180px) {
  .header {
    .text--weight-bold,
    .sprint-name {display: none;}
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  @import 'responsive/_tablet';
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  @import 'responsive/_phone';
}
