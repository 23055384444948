.input-group {
  @include column;
}

.textarea {
  font-family: $arial;
  font-size: 14px;
  // line-height: 18px;

  width: 100%;

  resize: none;

  border: none;
  outline: none;

  appearance: none;

  letter-spacing: normal;
}

.input {
  font-family: $arial;
  font-size: 14px;
  line-height: 18px;

  width: 100%;

  resize: none;

  border: none;
  outline: none;

  appearance: none;

  letter-spacing: normal;
}

.radio {
  position: relative;

  display: block;

  margin: 2px 0 4px;
  padding: 6px 6px 6px 28px;

  cursor: pointer;
  user-select: none;

  @include space-between-center-v;

  &__input {
    position: absolute;

    cursor: pointer;

    opacity: 0;
  }

  &__view {
    position: absolute;
    top: 6px;
    left: 6px;

    width: 16px;
    height: 16px;

    border: 1px solid $grayDm;
    border-radius: 50%;
    background-color: $white;

    &:after {
      position: absolute;

      display: none;

      width: 10px;
      height: 10px;
      margin: 2px;

      content: '';

      border-radius: 50%;
      background-color: $black;
    }
  }

  &:hover .radio__input ~ .radio__view {
    background-color: $grayLt;
  }

  &__input:checked ~ .radio__view:after {
    display: block;
  }
}

.range__input {
  width: 100%;
  margin-bottom: 8px;

  appearance: none;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;

    background: $grayLt;
  }

  &::-webkit-slider-thumb {
    width: 8px;
    height: 16px;
    margin-top: -3px;

    cursor: pointer;

    border: none;
    background: $black;

    -webkit-appearance: none;
  }
}

.app-form {
  flex: 1;

  &__addon a {
    color: #000;
    text-decoration: underline;
    font-size: 14px;
  }

  &__addon + &__addon {
    margin-top: 7px;
  }

  &__addon a:hover {
    color: #000;
    text-decoration: none;
  }
}
