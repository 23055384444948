// Columns
.header {
  height: auto;
  padding: 6px 12px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;

  &.collapsed {
    .logo {
      display: none;
    }

    .sprint-name {
      display: none;
    }
  }

  .left {
    flex-direction: row;
    align-items: flex-start;

    .text--size-l {
      font-size: 14px;
      margin-left: 6px;
      margin-right: 0;
    }

    .logo {
      margin: 0;
      width: 20px;
    }

    .text--weight-bold:after {
      display: none;
    }

    .sprint-name {
      display: none;
    }
  }

  .right {
    .header__nav {
      display: none;
    }

    .header-mobile {
      font-size: 32px;
      cursor: pointer;
      display: block;

      &__nav {
        position: absolute;
        border-top: 1px solid $grayDr;
        left: 0;
        top: 100%;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        z-index: 2;
        background-color: $black;
        user-select: none;
        display: block;

        &.open {
          max-height: 100vh;
        }

        .header-mobile__li {
          padding: 8px 12px;
          border-bottom: 1px solid $grayDr;

          .text--weight-bold {
            font-size: 18px;
            color: $white;
          }

          .button--role-mobile-nav {
            font-size: 18px;
            background: none;
            color: $white;
            font-weight: bold;
            text-align: left;
            border: none;
            text-decoration: underline;
          }

          ul .header-mobile__li {
            border: none;
            padding-left: 0;

            .button {
              font-weight: normal;
            }
          }
        }
      }
    }

    .button--role-feedback {
      display: none;
    }
  }
}

.main {
  @include center-h;
  margin-top: 50px;
  height: auto;

  &--admin.ant-layout-content.main-content,
  &-content.ant-layout-content {
    padding-left: 0;
    padding-right: 0;
  }

  .map {
    flex-direction: column;
    padding: 10px 6px 0;
    height: auto;
    min-height: calc(100vh - 52px);
    width: 100%;

    .container {
      width: auto;
      margin: 0;
      height: auto;

      .h-group {
        flex-wrap: wrap;
        height: auto;

        .h4 {
          display: flex;
          flex-direction: column;

          span {
            margin-bottom: 6px;
            
            &:after {
              display: none;
            }
          }

          .user-name {
            margin-left: 0;
          }
        }
      }

      &:not(.container--workout) .select-group {
        display: none;
      }

      .experiment {
        .col__item {
          min-height: auto;
        }
      }

      .col-group {
        flex-direction: column;
        box-shadow: none;
      }

      .col {
        padding: 14px 6px 6px 6px;
        margin-bottom: 4px;
        border-right: none;
        height: auto;
        min-height: 140px;

        width: 100%;

        &__wrapper {
          padding: 0;
          
          .col:first-child {
            width: calc(100% - 12px);
            border: 5px solid $grayLt;
          }
        }

        &--size {
          &-md,
          &-lg,
          &-sm {
            width: 100%;
          }
        }

        &__content {
          min-height: 100px;
          height: auto;
          padding: 0;
        }

        &__item {
          width: 100%;
          margin-bottom: 24px;
          margin-left: 0;
          .fa-icon-group {

            display: block;
            top: 100%;
            left: 6px;
            right: auto;

            .fa-icon {
              font-size: 18px;

              &_untoggled {
                opacity: 0.8;
              }
            }
          }
        }

        &__sub {
          min-height: 100px;
          height: auto;
        }

        &.taking-stock {
          width: 100%;
          height: auto;
          overflow: hidden;
          box-shadow: none;

          .col__cell {
            width: 100%;

            .col__content {
              min-height: auto;
            }

            .col__item {
              margin-bottom: 10px;
            }

            &:first-child {
              .h4 {
                .tooltip__box {
                  width: 100%;
                }
              }
            }

            &__content {
              min-height: auto;
            }
          }
        }
      }
    }
  }
}

.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}

.app-panel {
  width: 100%;
  min-width: 100%;
}

.table--sprint-form {
  width: 100%;

  &.top-space {
    margin-top: 0;
  }

  .ant-col-16 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .ant-card-body {
    padding-bottom: 0;
  }
}

.table--participants {
  .ant-table-content {
    overflow: scroll;
  }

  .ant-table.ant-table-bordered .ant-table-footer {
    padding: 16px 0 0 0;
    background: transparent;
    border: 0;
    justify-content: space-between;
    display: flex;
  }
}

.table--scrollable .ant-table-container {
  overflow: scroll;

  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    display: none;
  }
}

.ant-table-thead {
  white-space: nowrap;
}

.row-table-buttons {
  &.ant-row {
    margin-bottom: 0;
  }

  .ant-col {
    margin-bottom: 20px;
  }

  .ant-row-end {
    justify-content: space-between;
    margin-bottom: 0;

    .ant-col {
      flex: 0 0 100% !important;
    }
  }
}

.footer {
  position: static;
}

.card, .card:before {
  width: 100%;
}

.card {
  margin-top: 20px;
}

.dashboard-sprints {
  margin-left: -20px;
  margin-right: -20px;

  .ant-tabs-bar {
    padding: 0 20px;
  }

  // .ant-collapse-item:last-child {
  //   border-bottom: 0;
  // }
}
