*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;

  outline: none;
}

html,
body,
button {
  font-family: $arial !important;
  -webkit-font-smoothing: antialiased;
}

#root,
.main {
  height: 100%;
}

::-webkit-scrollbar {
  width: 0;

  background-color: $subWhite;
}

.link_disabled {
  pointer-events: none;
  opacity: .5;
}

.dragged {
  position: relative;
  width: 302px;
  margin: 2px 10px 4px;
  padding: 6px 12px 6px 6px;
  transition: .2s ease;
  cursor: pointer;
  background-color: #f3f3f3;
  z-index: 999;
}

.card-badge {
  display: none;
}

// circle for unread feedback
.unread {
  display: inline;
  font-size: 11px;
  margin-right: 5px;
  color: $blue;
}

.superscript {
  position: relative;
  margin-right: 6px;

  &--gap {
    margin-right: 10px;
  }

   &--copy:after{
    content: '©';
    position: absolute;
    font-size: 11px;
   }

  &:after {
    content: '®';
    position: absolute;
    font-size: 11px;
    height: 8px;
    top: 0px;
    line-height: 1;
  }
}

.table__actions {
  white-space: nowrap;
}

.ant-layout-content.main-content {
  padding: 20px;
  min-height: calc(100vh - 52px - 38px);

  &--auth {
    display: flex;
    padding-top: 50px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

.page-loader {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
}

/* RESET ANTD STYLES */
body {
  font-size: initial !important;
  line-height: initial !important;
  color: #000 !important;
}

ul, p {
  margin-bottom: 0 !important;
}

.h4, h3, h5, h6 {
  font-weight: 700 !important;
  color: #000;
}

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.flex-wrapper {
  display: flex;

  &--column {
    @include column;
  }

  &--center-h {
    @include center-h;
  }
}

.quill-area {
  .ql-container {
    height: 350px;
  }
}

.ant-table-row-level-0 {
  cursor: pointer;
}

.ant-dropdown-link {
  margin-right: 20px;
}

.pointer {
  cursor: pointer;
}

.osano-cm-widget { display: none; }

.profile__drawer .ant-drawer-content-wrapper {
  max-width: 500px;
  width: 100% !important;
}

.card__tag {
  margin-bottom: 20px;
}

.sprint-card {
  margin-top: 20px !important;

  .ant-card-head {
    padding: 0 15px;
  }
  
  .ant-card-head-title {
    padding: 15px 15px 15px 0;
  }

  .ant-card-body {
    padding: 15px;
  }

  &__row {
    padding: 7px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
