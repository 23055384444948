.dropdown-target {
  position: relative;

  min-width: 220px;
  padding: 4px 10px 4px 10px;

  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  z-index: 3;

  &:hover {
    .dropdown-content {
      display: block;
    }
  }
}
.dropdown-content {
  font-size: 14px;
  font-weight: normal;

  position: absolute;
  z-index: 5;
  top: 34px;
  left: 0;
  right: 0;
  margin: auto;

  display: none;

  min-width: 220px;
  padding: 10px;

  background-color: $white;
  border: 1px solid $grayLt;

  box-shadow: 2px 2px 2px 0px rgba($black, .4);


  &:before {
    position: absolute;
    top: -10px;
    left: calc(50% - 10px);
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $white transparent;
  }

  .dropdown__list {
    font-size: 14px;

    list-style-type: none;

    li {
      padding-bottom: 8px;

      a {

        padding-bottom: 1px;

        color: $black;
        border-bottom: 1px solid $black;

        &.active,
        &:hover {
          border: none;
        }
        &.active {
          font-weight: bold;
        }
      }
    }
  }
}
