@media print {
  .header .right {
    display: none;
  }

  .main .map,
  .main .map .container .col {
    display: block;
    height: auto;
  }

  .main .map .container {
    display: block;
    width: 100%;
    padding: 0 20px;
    height: auto;
  }

  .col .tooltip__target {
    border-bottom: 0;
  }

  .main .map .container .col-group {
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
    max-width: 100%;
    display: block;
  }

  .main .map .container .col-group .col {
    border: 0;
  }
  
  .main .map .container .col-group .col:nth-child(n+2) {
    border-top: 2px solid $grayLt;
  }

  .main .map .container_assumptions .col-group .col:nth-child(n+1) {
    border-top: 2px solid $grayLt;
    border-left: 2px solid $grayLt;
  }

  .main .map .container .col-group .col__wb .wb__content {
    min-height: auto;
  }

  .col__content {
    height: auto;
  }

  .main .map .container .col-group .col__content,
  .main .map .container .col-group.taking-stock,
  .main .map .container .col-group .col__content-exp,
  .main .map .container_assumptions .col-group {
    height: auto;
  }

  .main .map .container .col-group .col {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-top: 20px;
    margin: 50px 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .main .map .container .col-group .col_m {
    vertical-align: top;
    display: inline-block;
    width: 100%;
  }

  .main .map .container {
    page-break-after: always;
    &:last-child {
      page-break-after: initial;
    }
  }
  // .main .map .big-assumptions,
  .main .map .container .col.taking-stock {
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  }

  .main .map .container .col-group.taking-stock,
  .main .map .container.t-s-2 {
    width: 100%;
  }

  .main .map .container .col-group .button--size-full,
  .button--role-experiment,
  .button--role-print,
  .tooltip:hover .tooltip__box {
    display: none;
  }

  .footer {
    display: block;
    position: static;
    padding: 12px;
    margin: 0;
    text-align: center;

    .text--size-sm {
      font-size: 16px;
    }
  }
}