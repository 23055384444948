.main .map .container {
  .col__item:hover {
    cursor: pointer;

    border-color: $grayLt;

    .fa-icon-group {
      @include column;
      top: -2px;
    }
  }

  .col__button:hover {
    color: $grayDr;
    text-decoration: underline;
  }
}
