.import-tool {
  & .ant-modal-body {
    padding: 40px;
  }

  .ant-alert,
  .ant-table-wrapper{
    margin-bottom: 15px;
  }

  .ant-alert-description p {
    padding: 5px 0;
  }

  .ant-table-container table {
    table-layout: fixed;
  }
}
