.main {
  margin-top: 60px;
}

.header .left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .text {
    font-size: 14px;
  }
  
  .sprint-name {
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 160px;
    overflow: hidden;
  }
}

.main .map .container .h-group .h4 {
  flex-direction: row;

  > span {
    margin-right: 20px;
  }
}

.ant-dropdown:not(.hint) {
  left: initial !important;
  right: 0;
}

.ant-table-content {
  overflow: scroll;
}

.ant-table-thead {
  white-space: nowrap;
}

.row-table-buttons {
  &.ant-row {
    margin-bottom: 0;
  }

  > .ant-col {
    margin-bottom: 20px;
  }

  .ant-row-end {
    .ant-col {
      flex: 0 0 30% !important;
    }
  }
}
