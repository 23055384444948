.fa-icon {
  font-size: 14px;
  padding: 2px;
  opacity: .8;
  transition: all ease-in .2s;

  &:hover {
    opacity: 1;
    background-color: rgba(55, 55, 55, .1);
  }

  &-group {
    position: absolute;
    z-index: 3;
    top: -2px;
    right: -18px;

    display: none;

    justify-content: flex-end;
    user-select: none;

    &:hover {
      background-color: $white;
      transition: all ease-in .2s;
    }
  }

  &_gray {
    color: $icoGray;
  }

  &_orange {
    color: $orange;
  }
}
